/* Local dependencies */
import { ContractType } from './createContracts/redux/types';
import { ContractStatus } from './types';

export const officeManagerTabs = [
  {
    label: 'validContracts',
    value: ContractStatus.ACTIVE_CONTRACTS,
  },
  {
    label: 'expiringContracts',
    value: ContractStatus.EXPIRING_CONTRACTS,
  },
  {
    label: 'archive',
    value: ContractStatus.ARCHIVED_CONTRACTS,
  },
];

export const createContractMenu = [
  {
    label: 'contractTemporaryStorage',
    link: '/contracts/create-contract/svh/',
    type: ContractType.TEMPORARY_STORAGE,
  },
  {
    label: 'contractCargoWareHouse',
    link: '/contracts/create-contract/customs-warehouse',
    type: ContractType.CUSTOMS_WAREHOUSE,
  },
  {
    label: 'contractCargoStorage',
    link: '/contracts/create-contract/customs-declaration',
    type: ContractType.CUSTOMS_DECLARATION,
  },
  {
    label: 'modelContract',
    link: '/contracts/create-contract/customs-declaration',
    type: ContractType.TYPICAL_CONTRACT,
  },
];  
