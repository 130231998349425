const errorMessageStyle = {
  fontSize: 'body',
  m: '.2rem 0 0 0',
  maxW: '270px',
  textColor: 'red',
};

const formStyle = {
  width: '-webkit-fill-available',
};

const defaultInputStyle = {
  borderColor: 'input_border_color',
  className: 'input',
  focusBg: 'none',
  focusBorderColor: 'primary_color',
  h: '40px',
  p: { l: '1rem', r: '1.3rem' },
  placeholdertextcolor: 'input_placeholer_color',
  rounded: '4px',
  textColor: 'table_cell_text_color',
  textSize: 'body',
  textWeight: '600',
};

const inputCurrentWeightStyle = {
  borderColor: 'input_border_color',
  className: 'input',
  focusBg: 'none',
  focusBorderColor: 'primary_color',
  h: '68px',
  p: { l: '1rem', r: '2.7rem' },
  placeholdertextcolor: 'input_placeholer_color',
  rounded: '4px',
  textAlign: 'center',
  textColor: 'table_cell_text_color',
  textSize: { md: 'heading', lg: 'display1' },
  textWeight: '700',
  w: '100rem',
};

const inputSuccessWeightStyle = {
  borderColor: 'input_border_color',
  className: 'input',
  focusBg: 'none',
  focusBorderColor: 'primary_color',
  h: '68px',
  p: { l: '1rem', r: '2.7rem' },
  placeholdertextcolor: 'input_placeholer_color',
  rounded: '4px',
  textAlign: 'center',
  textColor: 'success_color',
  textSize: { md: 'heading', lg: 'display1' },
  textWeight: '700',
  w: '100rem',
};

const labelStyle = {
  justify: 'left',
  maxW: '270px',
  m: '0 auto 0 0',
  textColor: 'backgroundTextColor',
  textSize: 'body',
  textTransform: 'uppercase',
  textWeight: '700',
};

const labelStyleIndividual = {
  justify: 'left',
  m: '0 auto 0 0',
  textColor: 'backgroundTextColor',
  textSize: 'body',
  textTransform: 'uppercase',
  textWeight: '700',
  p: '2rem 0 1rem 0',
};

const labelBack = {
  justify: 'left',
  m: '0 auto 0 0',
  textColor: 'text_color',
  textSize: 'body',
  textTransform: 'uppercase',
  textWeight: '700',
  p: '1rem 0 1rem 0',
};

const labelSecondaryStyle = {
  ...labelStyle,
  p: '0 0 12px  0 ',
  textTransform: undefined,
  textWeight: '400',
};

const rowStyle = {
  align: 'center',
  d: 'flex',
  m: '1rem 0 0 0',
  style: {
    whiteSpace: 'nowrap',
  },
};

const rowVehicleStyle = {
  align: 'center',
  d: 'flex',
  style: {
    whiteSpace: 'nowrap',
  },
};

const subMenuStyle = {
  ...labelStyle,
};

const wrapStyle = {
  bg: 'button_text_color',
  rounded: '4px',
  m: '0 auto',
  maxW: '320px',
  w: '320px',
  p: '0',
};

export {
  defaultInputStyle,
  errorMessageStyle,
  formStyle,
  inputCurrentWeightStyle,
  inputSuccessWeightStyle,
  labelBack,
  labelStyle,
  labelStyleIndividual,
  labelSecondaryStyle,
  rowStyle,
  rowVehicleStyle,
  subMenuStyle,
  wrapStyle,
};
