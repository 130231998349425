/* External dependencies */
import { Div, Col, Label } from 'atomize';
import React from 'react';

/* Local dependencies */
import formatMessage from '../../intl/formatMessage';
import TabButton from '../buttons/TabButton';
import { labelSecondaryStyle } from '../form/FormStyles';

interface TabProps {
  inputName: string;
  items: any[];
  value?: string;
  label?: string;
  onClick?: any;
}

export default function Tabs(props: TabProps) {
  const { inputName, items, onClick, value, label } = props;

  const isActive = (item) => item === value;
  const leftRounded = { tl: 'md', bl: 'md' };
  const rightRounded = { tr: 'md', br: 'md' };
  const shadow = '0 0 transparent, 1px 0 #c5cee0 inset';

  const getTabClassName = (value: string): string => {
    const activeTab = isActive(value) ? `tab--active` : '';

    return activeTab;
  };

  const getRoundedProps = (index: number) => {
    if (index === 0) {
      return leftRounded;
    }

    if (index === items.length - 1) {
      return rightRounded;
    }

    return {};
  };

  return (
    <>
      {label && (
        <Col>
          <Label {...labelSecondaryStyle}>{label}</Label>
        </Col>
      )}
      <Col size={{ xs: '12', lg: 'auto' }} style={{ padding: '0' }}>
        <Div
          d="flex"
          w="auto"
          overflow="auto"
          style={{ whiteSpace: 'nowrap' }}
          p={{ b: '1rem' }}
        >
          {items.map((item, index) => (
            <TabButton
              border="0"
              className={getTabClassName(item.value)}
              shadow={index !== 0 ? shadow : null}
              key={index}
              onClick={() =>
                onClick({
                  target: {
                    name: inputName,
                    value: item.value,
                  },
                })
              }
              rounded={getRoundedProps(index)}
              textColor={isActive(item.value) ? '' : 'backgroundTextColor'}
            >
              {formatMessage(item.label)}
            </TabButton>
          ))}
        </Div>
      </Col>
    </>
  );
}
