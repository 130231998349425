// External dependencies
import { Row, Col, Div, Text, Icon } from 'atomize';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Local dependencies
import formatMessage from '../../intl/formatMessage';
import { RootState } from '../../redux/store';
import PrimaryButton from '../buttons/PrimaryButton';
import DateTime from '../common/DateTime';
import useOutsideClick from '../common/useOutsideClick';
import Search from '../search/Search';
import Tabs from '../tabs/Tabs';
import { createContractMenu, officeManagerTabs } from './contracts-tabs';
import CustomSelect from './custom-select/customSelect';
import { listContracts } from './listContracts/redux/action';

export function OfficeManagerControlBar() {
  const [isBtnMenu, setIsBtnMenu] = useState(false);
  const ref = useRef(null);
  const { searchString, filter, loading } = useSelector(
    (state: RootState) => state.listContracts,
  );

  const dispatch = useDispatch();

  const findOrganization = formatMessage('findOrganization');

  useOutsideClick(ref, () => {
    setIsBtnMenu(false);
  });

  function onSearch(e) {
    dispatch(listContracts(e.target.value, 1, filter));
  }

  function resetSearchField() {
    onSearch({
      target: {
        value: '',
      },
    });
  }

  function handleCreateContract() {
    setIsBtnMenu(true);
  }

  function onFilterChange(e) {
    const { value } = e.target;

    dispatch(listContracts(searchString, 1, { ...filter, status: value }));
  }

  return (
    <Row m={{ y: '.5rem', x: '-.5rem' }}>
      <Col size={{ xs: '12', lg: '6' }}>
        {
          <Tabs
            inputName=""
            items={officeManagerTabs}
            onClick={onFilterChange}
            value={filter?.status}
          />
        }
      </Col>
      <Col
        size={{ xs: '12', lg: '6' }}
        d={{ xs: 'block', md: 'flex' }}
        justify={{ xs: 'flex-start', lg: 'flex-end' }}
      >
        <Div
          d={{ xs: 'block', md: 'flex' }}
          flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
        >
          <Div
            m={{ r: { xs: 0, sm: '1.5rem' } }}
            style={{ marginBottom: '1rem' }}
          >
            <Search
              onChange={onSearch}
              onClear={resetSearchField}
              placeholder={findOrganization}
              value={searchString}
            />
          </Div>
          <Div
            pos="relative"
            style={{ marginBottom: '1rem' }}
            className="wrapper-action"
          >
            <PrimaryButton
              onClick={handleCreateContract}
              icon="Plus"
              disabled={loading}
            >
              <Text textSize="little" style={{ whiteSpace: 'nowrap' }}>
                {formatMessage('createContractBtn')}
              </Text>
            </PrimaryButton>
            {isBtnMenu && (
              <CustomSelect
                onClick={() => setIsBtnMenu(false)}
                items={createContractMenu}
                reference={ref}
              />
            )}
          </Div>
        </Div>
      </Col>
    </Row>
  );
}

export function getOfficeManagerControlFields() {
  const sort = { direction: 'DESC' }; // непонятно с этим sort. Когда он нужен будет, добавьте в редаксе

  function onSortContracts() {
    // Этих тайпов не существует, создать и добавить при набодности
    // const sortQuery = {
    //   field: 'startDate',
    //   direction:
    //     sort.direction === SortDirection.ASC
    //       ? SortDirection.ASC
    //       : SortDirection.DESC,
    // };
    // dispatch(listContracts('', 1, filter, sortQuery));
  }

  return [
    {
      field: 'title',
      title: <FormattedMessage id="organization" />,
    },
    {
      field: (contract) => {
        return (
          <Div textAlign="right">
            <DateTime date={contract.startDate} />
          </Div>
        );
      },
      title: (
        <Div textAlign="right">
          <FormattedMessage id="creationDate" />
          <Icon
            onClick={onSortContracts}
            name={sort && sort?.direction === 'ASC' ? 'UpArrow' : 'DownArrow'}
            size="20px"
            style={{ verticalAlign: 'middle', cursor: 'pointer' }}
          />
        </Div>
      ),
    },
  ];
}
