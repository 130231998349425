/* External dependencies */
import { Button, Input, Text } from 'atomize';
import React from 'react';
import { FormattedMessage } from 'react-intl';

/* Local dependencies */
import './styles.scss';

interface PrevNextButtonProps {
  children: any;
  disabled: any;
  onClick: () => void;
  style?: {};
}

export default function PrevNextButton(props: PrevNextButtonProps) {
  const { children, disabled, onClick, style } = props;

  return (
    <Button
      className={disabled ? 'disabled_button_pagination' : ''}
      border={{ xs: '1px solid', md: 'none' }}
      borderColor="backgroundTextColor"
      bg="transparent"
      disabled={disabled}
      hoverTextColor="primary_color"
      hoverBorderColor="primary_color"
      m={{ r: '0.5rem' }}
      onClick={onClick}
      p={{ xs: { x: '10px', y: '12px' }, md: { x: '16px' } }}
      rounded="none"
      style={style}
      textSize="14px"
      textColor="backgroundTextColor"
      textWeight="700"
    >
      {children}
    </Button>
  );
}

interface GoToPageProps {
  lastPage: number;
  onChange: (item: number) => void;
}

export function GoToPage({ lastPage, onChange }: GoToPageProps) {
  return (
    <>
      <Text
        p={{ x: '16px', y: '12px' }}
        textSize="14px"
        textColor="backgroundTextColor"
        textWeight="700"
        style={{
          lineHeight: '16px',
          whiteSpace: 'nowrap',
        }}
      >
        <FormattedMessage id="goTo" />
      </Text>
      <Input
        textAlign="center"
        h="40px"
        max={lastPage}
        min="1"
        onChange={(e) => onChange(parseInt(e.target.value, 10))}
        p="4px"
        textSize="14px"
        textColor="primary_color"
        textWeight="700"
        type="number"
        w="48px"
      />
    </>
  );
}
