/* External dependencies */
import { Button } from 'atomize';
import React from 'react';

interface PageNumberProps {
  isActive: boolean;
  onClick: (number: number) => void;
  pageNumber: number;
}

export default function PageNumber({
  isActive,
  onClick,
  pageNumber,
}: PageNumberProps) {
  return (
    <Button
      bg="transparent"
      border="1px solid"
      borderColor={isActive ? 'primary_color' : 'backgroundTextColor'}
      hoverTextColor="primary_color"
      hoverBg="primary_hover_color"
      hoverBorderColor="primary_color"
      m={{ r: '0.5rem' }}
      onClick={() => onClick(pageNumber)}
      p={{ x: '16px', y: '12px' }}
      rounded="2px"
      textSize="14px"
      textColor={isActive ? 'primary_color' : 'backgroundTextColor'}
      textWeight="700"
    >
      {pageNumber}
    </Button>
  );
}
