/* External dependencies */
import { Div } from 'atomize';
import React from 'react';

export default function Triple() {
  return (
    <Div
      m={{ r: '0.5rem' }}
      p="10px"
      textSize="14px"
      textWeight="700"
      textColor="backgroundTextColor"
    >
      &#8230;
    </Div>
  );
}
