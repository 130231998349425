/* External dependencies */
import { Div, Icon, Input } from 'atomize';
import React from 'react';

/* Local dependencies */
import { defaultInputStyle } from '../form/FormStyles';

interface SearchDefaultProps {
  onChange: (e) => void;
  onClear: () => void;
  placeholder: React.ReactChild;
  value: string;
}

export default function Search({
  onChange,
  onClear,
  placeholder,
  value,
}: SearchDefaultProps) {
  return (
    <Div maxW="332px" w={{ xs: '100%', sm: '332px' }}>
      <Input
        bg="input_bg"
        id="searchInput"
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        pos="relative"
        {...defaultInputStyle}
        suffix={
          <Icon
            id="searchIcon"
            name={value?.length > 0 ? 'Cross' : 'Search'}
            color={value?.length > 0 ? 'primary_color' : 'backgroundTextColor'}
            border="1px solid"
            style={{ borderColor: '#E4E9F2' }}
            onClick={onClear}
            size="28px"
            cursor="pointer"
            pos="absolute"
            top="50%"
            right="15px"
            transform="translateY(-50%)"
          />
        }
      />
    </Div>
  );
}
