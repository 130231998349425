/* External dependencies */
import { Col, Container, Div, Row } from 'atomize';
import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-react-intl';

/* Local dependencies */
import Pagination from '../pagination/Pagination';
import NotFound from '../search/NotFound';
import Spinner from '../spinner/Spinner';
import './table.scss';
import { DEFAULT_PAGE_SIZE } from '../common/helpers';

export enum RowSelectionType {
  MULTI = 'MULTI',
  NONE = 'NONE',
  SINGLE = 'SINGLE',
}

interface TableProps {
  activeItem?: "active" | "small"
  columns: any;
  controlBar: React.ReactNode;
  currentPage: any;
  className?: string;
  emptyMessage: React.ReactChild;
  items: any[];
  loading?: boolean;
  onPageChange: (page: number) => void;
  onSelect?: (selectedItem: any) => void;
  searchString?: string;
  selectedItems?: any[];
  selectionType?: RowSelectionType;
  stickyCol?: number;
  topBar: React.ReactNode;
  totalItemsCount: number;
}

function TableHeader({ controlBar, topBar }) {
  return (
    <>
      <Row m="24px 0">
        <Col
          size="12"
          d="flex"
          justify="space-between"
          style={{ alignItems: 'center', padding: '0' }}
        >
          {topBar}
        </Col>
      </Row>
      {controlBar}
    </>
  );
}

function TableEmptyMessage({ itemsCount, emptyMessage, searchString }) {
  if (itemsCount) {
    return null;
  }

  return (
    <NotFound
      text={searchString ? <FormattedMessage id="noMatch" /> : emptyMessage}
    />
  );
}

interface RowSelectionProps {
  isActive: boolean | null;
  onSelect?: () => void | undefined;
  selectionType?: RowSelectionType;
  value: number;
}

function RowSelection(props: RowSelectionProps) {
  const { isActive, onSelect, selectionType, value } = props;

  const radioSpanStyle = {
    background: isActive ? '#fff' : 'transparent',
  };

  const radioBtnStyle = {
    background: isActive ? '#3366ff' : 'rgba(143, 155, 179, 0.16)',
    border: isActive ? '1px solid #fff' : '1px solid #c5cee0',
    cursor: 'pointer',
  };

  let component;

  if (selectionType === RowSelectionType.MULTI) {
    component = <input type="checkbox" onChange={onSelect} />;
  } else if (selectionType === RowSelectionType.SINGLE) {
    component = (
      <label
        className="singleSelectLabel"
        onClick={onSelect}
        style={radioBtnStyle}
      >
        <span style={radioSpanStyle}></span>
      </label>
    );
  } else {
    component = value;
  }

  return <td>{component}</td>;
}

export default function Table(props: TableProps) {
  const {
    columns,
    controlBar,
    currentPage,
    className,
    emptyMessage,
    items,
    loading,
    onPageChange,
    onSelect,
    searchString,
    selectedItems,
    selectionType,
    activeItem = "active",
    stickyCol,
    topBar,
    totalItemsCount,
  } = props;
  const pageSize: number = DEFAULT_PAGE_SIZE;

  let data;

  if (items.length) {
    data = items.map((item, index) => (
      <tr
        key={index}
        className={
          selectionType && isItemSelected(selectedItems, item) ? activeItem : ''
        }
      >
        <RowSelection
          value={(currentPage - 1) * pageSize + index + 1}
          isActive={selectionType ? isItemSelected(selectedItems, item) : null}
          onSelect={() => onSelect(item)}
          selectionType={selectionType}
        />
        {columns.map(({ field }, indexCol) => {
          function getData(field) {
            if (typeof field !== 'string' && field(item) === 'ACTIVE') {
              if (item.vehicleProfile.tareWeight === 0) {
                return <FormattedMessage id="taring" />;
              }

              return <FormattedMessage id="ACTIVE" />;
            }

            if (typeof field !== 'string' && field(item) === 'EXPIRED') {
              return <FormattedMessage id="taring" />;
            }

            if (typeof field === 'string') {
              return item[field];
            }

            return field(item);
          }

          return (
            <td
              key={indexCol}
              className={indexCol === stickyCol ? 'sticky-col' : ''}
            >
              {getData(field)}
            </td>
          );
        })}
      </tr>
    ));
  } else {
    data = (
      <tr>
        <td></td>
        <td colSpan={columns.length}>
          <TableEmptyMessage
            emptyMessage={emptyMessage}
            itemsCount={items.length}
            searchString={searchString}
          />
        </td>
      </tr>
    );
  }

  return (
    <Container>
      <TableHeader controlBar={controlBar} topBar={topBar} />
      <Div pos="relative" style={{ overflowX: 'auto' }}>
        {loading && <Spinner />}
        <table className={className}>
          <thead>
            <tr>
              <th>
                {selectionType ? null : <FormattedMessage id="numberSymbol" />}
              </th>
              {columns.map((column, index) => (
                <th
                  key={index}
                  className={index === stickyCol ? 'sticky-col' : ''}
                >
                  {column.title} {column.icon}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{data}</tbody>
        </table>
      </Div>
      <Pagination
        currentPage={currentPage}
        itemsCount={totalItemsCount}
        pageSize={pageSize}
        onPageChange={onPageChange}
      />
    </Container>
  );
}

export function isItemSelected(selectedItems: any[], item: any): boolean {
  return selectedItems.some((e) => e.id === item.id);
}
