/* External dependencies */
import { Div } from 'atomize';
import React from 'react';

export default function PopupActions({ children }) {
  return (
    <Div
      d="flex"
      justify="flex-end"
      p={{ x: '1.5rem', y: '1rem' }}
      className={children.length > 1 && 'popup__actions'}
    >
      {children}
    </Div>
  );
}
