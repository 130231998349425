import { Div, Icon } from 'atomize';
import React from 'react';

export default function Spinner({ color = 'table_cell_text_color' }) {
  const minHeight = '40px';

  return (
    <Div
      align="center"
      d="flex"
      justify="center"
      h="50%"
      minH={minHeight}
      pos="absolute"
      style={{
        zIndex: 11,
      }}
      w="100%"
    >
      <Icon color={color} name="Loading" size={minHeight} />
    </Div>
  );
}

export function PageSpinner() {
  return (
    <Div minH="4rem" pos="relative">
      <Spinner />
    </Div>
  );
}
