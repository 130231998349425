/* External dependencies */
import * as Atomize from 'atomize';
import { Icon } from 'atomize';
import React from 'react';

/* Local dependencies */
import './styles.scss';

type ButtonType = 'primary' | 'success' | 'danger' | 'basic' | 'tab';

export interface ButtonProps {
  border?: string;
  children: React.ReactNode;
  className?: string;
  disabled?: Boolean;
  icon?: string;
  loading?: boolean;
  onClick?: () => void;
  rounded?: {};
  textColor?: string;
  size?: string;
  shadow?: string;
}

interface CustomButtonProps extends ButtonProps {
  type?: ButtonType;
}

export default function Button(props: CustomButtonProps) {
  const {
    border,
    children,
    className,
    disabled,
    icon,
    loading,
    onClick,
    rounded,
    size,
    shadow,
    textColor,
    type,
    ...rest
  } = props;

  const params = {
    xlg: {
      w: '100%',
      h: '4.5rem',
    },
    lg: {
      w: '100%',
      h: '3.5rem',
    },
    md: {
      w: '100%',
      h: '2.5rem',
    },
  };

  return (
    <Atomize.Button
      bg={type ? `${type}_color` : 'none'}
      border={border || '1px solid'}
      borderColor={type ? `${type}_color` : 'transparent'}
      className={className}
      disabled={disabled}
      hoverBg={`${type}_hover_color`}
      hoverBorderColor={type ? `${type}_color` : 'backgroundTextColor'}
      hoverTextColor={type ? `${type}_hover_text_color` : 'text_color'}
      h={size && params[size] && params[size].h}
      isLoading={loading}
      m="0"
      onClick={onClick}
      p={{ l: '18px', r: icon ? '10px' : '18px', y: '12px' }}
      rounded={rounded}
      textSize="caption"
      textTransform={type ? 'uppercase' : 'capitalize'}
      textWeight="700"
      textColor={textColor || 'button_text_color'}
      style={{ lineHeight: '16px' }}
      shadow={shadow}
      suffix={
        icon && (
          <Icon
            className={type}
            color="white"
            name={icon}
            size="21px"
            m={{ l: '11px' }}
          />
        )
      }
      w={size && params[size] && params[size].w}
      {...rest}
    >
      {children}
    </Atomize.Button>
  );
}
