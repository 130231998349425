/* External dependencies */
import { Div } from 'atomize';
import React from 'react';

export default function PopupContent({ children }) {
  return (
    <Div
      p={{ x: '1.5rem', y: '1rem' }}
      border={{ b: '1px solid', t: '1px solid' }}
      borderColor="#EDF1F7"
      d="flex"
      textAlign="left"
    >
      {children}
    </Div>
  );
}
