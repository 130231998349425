/* External dependencies */
import { Div, Icon, Text } from 'atomize';
import { navigate } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/* Local dependencies */
import formatMessage from '../../intl/formatMessage';
import { RootState } from '../../redux/store';
import { getUserRole, isBrowser, setLocalStorage } from '../common/helpers';
import { UserRoles } from '../common/roles';
import ErrorPopup from '../popup/ErrorPopup';
import Table, { RowSelectionType } from '../tables/Table';
import { ContractType } from './createContracts/redux/types';
import {
  listContracts,
  resetListContracts,
  resetError,
} from './listContracts/redux/action';
import { ListContractsState } from './listContracts/redux/reducer';
import {
  OfficeManagerControlBar,
  getOfficeManagerControlFields,
} from './OfficeManagerControlBar';

export default function ContractsComponent() {
  const {
    contracts,
    currentPage,
    filter,
    loading,
    searchString,
    total,
    error,
  } = useSelector(
    (state: RootState): ListContractsState => state.listContracts,
  );
  const { currentUser } = useSelector((state: RootState) => state.login);

  useEffect(() => {
    dispatch(listContracts());

    return () => {
      dispatch(resetListContracts());
    };
  }, []);

  const dispatch = useDispatch();

  const errorMessage = error?.message;
  const errorOccurred = formatMessage('errorOccurred');

  function onPageChange(page) {
    dispatch(listContracts(searchString, page, filter));
  }

  function handleGetContract({ id }) {
    isBrowser && setLocalStorage('contractId', id);

    navigate('contract-details');
  }

  function renderContractName(contract, index) {
    return (
      <Div
        key={index}
        d="flex"
        style={{ gap: '10px' }}
        cursor="pointer"
        onClick={() => handleGetContract(contract)}
      >
        {contract && (
          <>
            {contract?.consigneeProfile ? (
              contract?.consigneeProfile.name
            ) : (
              <FormattedMessage id="modelContract" />
            )}
            {contract?.type !== ContractType.TYPICAL_CONTRACT && (
              <Text bg="table_cell_background" p="3px 6px 3px 5px" rounded="sm">
                <FormattedMessage id={contract.type || 'contract'} />
              </Text>
            )}
          </>
        )}
      </Div>
    );
  }

  const topBar = (
    <Text
      textColor="table_cell_text_color"
      textSize={{ xs: 'mobileTitle', md: 'title' }}
      textWeight="700"
    >
      <FormattedMessage id="Contracts" />(
      {loading ? <Icon m="0 .62rem" name="Loading3" size="20px" /> : total})
    </Text>
  );

  return (
    <>
      <Table
        currentPage={currentPage}
        columns={getFields()}
        className="table"
        controlBar={getControlBar()}
        emptyMessage={<FormattedMessage id="noContracts" />}
        items={contracts.map((contract, index) => {
          return {
            ...contract,
            title: renderContractName(contract, index),
          };
        })}
        loading={loading}
        onPageChange={onPageChange}
        searchString={searchString}
        selectedItems={[contracts[0]]}
        stickyCol={1}
        activeItem="small"
        topBar={topBar}
        selectionType={RowSelectionType.NONE}
        totalItemsCount={total}
      />
      {error && (
        <ErrorPopup
          onSubmit={() => dispatch(resetError())}
          submitText={<FormattedMessage id="close" />}
          title={errorOccurred}
        >
          {errorMessage}
        </ErrorPopup>
      )}
    </>
  );

  function getControlBar() {
    const userRole = getUserRole(currentUser);

    switch (userRole) {
      case UserRoles.BTS_OFFICE_MANAGER:
        return <OfficeManagerControlBar />;
      case UserRoles.BTS_ADMIN:
        return <OfficeManagerControlBar />;
      default:
        return new Error(`Unrecognized Cognito user group role "${userRole}".`);
    }
  }

  function getFields() {
    const userRole = getUserRole(currentUser);

    switch (userRole) {
      case UserRoles.BTS_OFFICE_MANAGER:
        return getOfficeManagerControlFields();
      case UserRoles.BTS_ADMIN:
        return getOfficeManagerControlFields();
      default:
        return new Error(`Unrecognized Cognito user group role "${userRole}".`);
    }
  }
}
