/* External dependencies */
import { Div, Modal, Text } from 'atomize';
import React from 'react';

/* Local dependencies */
import './Popup.scss';

interface PopupProps {
  children: React.ReactNode;
  maxWidth?: string;
  minWidth?: string;
  onClose?: () => void;
  title: React.ReactChild;
}

export default function Popup({
  title,
  onClose,
  children,
  maxWidth = '400px',
  minWidth = "auto",
}: PopupProps) {
  const headerColor = '#151A30';
  const headerSize = '18px';

  return (
    <Modal
      align="center"
      onClose={onClose}
      rounded="sm"
      w="auto"
      maxW={maxWidth}
      minW={minWidth}
      p="none"
      isOpen={true}
      className="popup"
    >
      <Text
        tag="h1"
        textAlign="left"
        textSize={headerSize}
        textColor={headerColor}
        p={{ x: '1.5rem', y: '1rem' }}
      >
        {title}
      </Text>
      <Div border="1px solid" borderColor="border_color">
        {children}
      </Div>
    </Modal>
  );
}
