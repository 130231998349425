/* External dependencies */
import React from 'react';

/* Local dependencies */
import { IconProps } from './types';

export default function SearchIcon({ width, height }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 60 60"
    >
      <path
        fill="#C5CEE0"
        fillRule="evenodd"
        d="M6.667 26.667c0-11.03 8.97-20 20-20s20 8.97 20 20-8.97 20-20 20-20-8.97-20-20zM59.023 54.31L47.707 42.99c3.51-4.513 5.626-10.17 5.626-16.323C53.333 11.963 41.37 0 26.667 0 11.963 0 0 11.963 0 26.667 0 41.37 11.963 53.333 26.667 53.333c6.153 0 11.81-2.116 16.323-5.626l11.32 11.316c.65.65 1.503.977 2.357.977.853 0 1.706-.327 2.356-.977a3.33 3.33 0 000-4.713z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
