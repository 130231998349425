/* External dependencies */
import React from 'react';

/* Local dependencies */
import Button, { ButtonProps } from './Button';

export default function DangerButton(props: ButtonProps) {
  return (
    <Button {...props} type="danger">
      {props.children}
    </Button>
  );
}
