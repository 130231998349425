/* External dependencies */
import moment from 'moment';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React from 'react';

/* Local dependencies */
import formatMessage from '../../intl/formatMessage';
import {
  DATE_FORMAT,
  DATE_FORMAT_FULL_YEAR,
  DEFAULT_TIMEZONE,
  TIME_FORMAT,
} from './renderDate';

interface DateTimeProps {
  date?: any;
}

// it formats as '26.02.21 в 14:40'
export default function DateTime({ date }: DateTimeProps) {
  return (
    <FormattedMessage
      id="dateTime"
      values={{
        valueDate: moment(date).utcOffset(DEFAULT_TIMEZONE).format(DATE_FORMAT),
        valueTime: moment(date).utcOffset(DEFAULT_TIMEZONE).format(TIME_FORMAT),
      }}
    />
  );
}

export function DateTimeFormatMessage(date) {
  return formatMessage('dateTime', {
    valueDate: moment(date).utcOffset(DEFAULT_TIMEZONE).format(DATE_FORMAT),
    valueTime: moment(date).utcOffset(DEFAULT_TIMEZONE).format(TIME_FORMAT),
  });
}

export function dateNowFormatMessage(date) {
  return formatMessage('dateNow', {
    valueDate: moment(date)
      .utcOffset(DEFAULT_TIMEZONE)
      .format(DATE_FORMAT_FULL_YEAR),
  });
}
