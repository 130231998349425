/* External dependencies */
import { Div } from 'atomize';
import { navigate } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React from 'react';
import { useDispatch } from 'react-redux';

/* Local dependencies */
import './customSelect.scss';
import { getContract } from '../getContract/redux/actions';

interface CustomSelectProps {
  items: any[];
  reference: any;
  onClick: () => void;
}

export default function CustomSelect({
  items,
  reference,
  onClick,
}: CustomSelectProps) {
  const dispatch = useDispatch();

  function onNavigate(item) {
    dispatch(getContract('TYPICAL_CONTRACT'));
    navigate('create');
    localStorage.setItem('contractType', item.type);
  }

  return (
    <Div
      ref={reference}
      pos="absolute"
      top="0"
      left={{ xs: '0', md: 'auto' }}
      right={{ md: '0' }}
      style={{
        zIndex: '100',
        boxShadow: '0 4px 8px 0  rgba(143, 155, 179, .10)',
        borderRadius: '4px',
      }}
      onClick={onClick}
    >
      {items.map((item, ind) => (
        <Div
          className="custom-select-contract"
          key={ind}
          cursor="pointer"
          onClick={() => onNavigate(item)}
        >
          <FormattedMessage id={item.label} />
        </Div>
      ))}
    </Div>
  );
}
