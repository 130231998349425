/* External dependencies */
import { Router } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';
import React from 'react';
import { Helmet } from 'react-helmet';

/* Local dependencies */
import Layout from '../../components/layout';
import ContractsComponent from '../../components/contracts/index';
import formatMessage from '../../intl/formatMessage';

export default function Contracts() {
  const intl = useIntl();
  const locale = intl.locale;
  const title = formatMessage('Contracts');
  const {
    site: {
      siteMetadata: { titleTemplate },
    },
  } = useStaticQuery(query);

  return (
    <Layout>
      <Helmet>
        <title>{titleTemplate.replace('%s', title)}</title>
      </Helmet>
      <Router basepath={`/${locale}`}>
        <ContractsComponent path="/contracts" />
      </Router>
    </Layout>
  );
}

const query = graphql`
  query {
    site {
      siteMetadata {
        titleTemplate
      }
    }
  }
`;
