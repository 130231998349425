/* External dependencies */
import React from 'react';

/* Local dependencies */
import CancelButton from '../buttons/CancelButton';
import DangerButton from '../buttons/DangerButton';
import Popup from './Popup';
import PopupActions from './PopupActions';
import PopupContent from './PopupContent';

interface ErrorPopupProps {
  canselText?: any;
  canselSubmit?: () => void;
  children: React.ReactNode;
  onSubmit?: () => void;
  submitText?: any;
  title: any;
}

export default function ErrorPopup({
  canselSubmit,
  canselText,
  children,
  onSubmit,
  submitText,
  title,
}: ErrorPopupProps) {
  return (
    <Popup title={title}>
      <PopupContent>{children}</PopupContent>
      <PopupActions>
        <CancelButton textColor="text_color" onClick={canselSubmit}>
          {canselText}
        </CancelButton>
        <DangerButton onClick={onSubmit}>{submitText}</DangerButton>
      </PopupActions>
    </Popup>
  );
}
