/* External dependencies */
import { Col, Icon, Row } from 'atomize';
import React from 'react';
import { FormattedMessage } from 'react-intl';

/* Local dependencies */
import { isMobile } from '../common/helpers';
import PageNumber from './PageNumber';
import PrevNextButton, { GoToPage } from './PrevNextButton';
import Triple from './Triple';
import { usePagination, DOTS } from './usePagination';

interface PaginationProps {
  currentPage: any;
  itemsCount: number;
  onPageChange: (page: number) => void;
  pageSize: number;
}

export default function Pagination(props: PaginationProps) {
  const { currentPage, itemsCount, onPageChange, pageSize } = props;
  const isMobileWidth = isMobile();

  if (itemsCount <= pageSize) {
    return null;
  }

  const pages = usePagination({
    currentPage,
    itemsCount,
    pageSize,
  });

  const onNext = () => {
    onChange(currentPage + 1);
  };

  const onPrevious = () => {
    onChange(currentPage - 1);
  };

  function onChange(page) {
    if (page < 1) {
      onPageChange(1);
    } else if (page > lastPage) {
      onPageChange(lastPage);
    } else {
      onPageChange(page);
    }
  }

  const lastPage = pages[pages.length - 1];

  return (
    <Row
      m={{ t: '36px' }}
      flexDir={{ xs: 'column', md: 'row' }}
      justify="center"
    >
      <Col>
        <div id="pagination">
          <PrevNextButton
            disabled={currentPage === 1}
            onClick={onPrevious}
            style={{
              borderTopLeftRadius: '4px',
              borderBottomLeftRadius: '4px',
            }}
          >
            {isMobileWidth ? (
              <Icon
                name="LeftArrow"
                color={
                  currentPage === 1
                    ? 'arrow_disabled_color'
                    : 'backgroundTextColor'
                }
                hoverColor="primary_color"
                size="20px"
              />
            ) : (
              <FormattedMessage id="prevPage" />
            )}
          </PrevNextButton>
          {pages.map((pageNumber, index) => {
            if (pageNumber === DOTS) {
              return <Triple key={index} />;
            }

            return (
              <PageNumber
                key={index}
                isActive={currentPage == pageNumber}
                onClick={onPageChange}
                pageNumber={pageNumber}
              />
            );
          })}
          <PrevNextButton
            disabled={currentPage === lastPage}
            onClick={onNext}
            style={{
              borderTopRightRadius: '4px',
              borderBottomRightRadius: '4px',
            }}
          >
            {isMobileWidth ? (
              <Icon
                name="RightArrow"
                color={
                  currentPage === lastPage
                    ? 'arrow_disabled_color'
                    : 'backgroundTextColor'
                }
                hoverColor="primary_color"
                size="20px"
              />
            ) : (
              <FormattedMessage id="nextPage" />
            )}
          </PrevNextButton>
        </div>
      </Col>
      <Col d="flex">
        <GoToPage lastPage={lastPage} onChange={onChange} />
      </Col>
    </Row>
  );
}
