/* External dependencies */
import { Col, Container, Row, Text } from 'atomize';
import React from 'react';

/* Local dependencies */
import SearchIcon from '../../svg/SearchIcon';

interface NotFoundProps {
  text: React.ReactNode;
}

export default function NotFound({ text }: NotFoundProps) {
  const rowStyle = {
    height: '50vh',
    width: '100vw',
    maxWidth: '1200px',
    display: 'table-cell',
    verticalAlign: 'middle',
    overflowY: 'hidden',
    textAlign: 'center',
  };

  return (
    <Container>
      <Row style={rowStyle} flexDir="column">
        <Col>
          <SearchIcon width={60} height={60} />
        </Col>
        <Col>
          <Text
            textSize="36px"
            textWeight="700"
            textColor="not_found_text_color"
            m={{ t: '40px' }}
          >
            {text}
          </Text>
        </Col>
      </Row>
    </Container>
  );
}
